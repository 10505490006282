import React from 'react'
import {SimpleDialog} from './components/Misc/SimpleDialog'
import RouteConfig from './router/routes'
import {SnackbarProvider} from 'notistack'

function App() {
    return (
        <SnackbarProvider
            maxSnack={5}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <div className='App'>
                <RouteConfig/>
            </div>
            <SimpleDialog width={''}/>
        </SnackbarProvider>
    )
}

export default App
