import {IAppState} from '../../utils/models/IAppState'
import {
    APP_OVERPAGE,
    APP_SET_CURRENT_ENTITY,
    APP_SET_DIALOG_CURRENT_ENTITY_ITEM,
    APP_SET_DIALOG_ENTITY,
    APP_SET_DIALOG_ITEM_ID, APP_SET_DIALOG_MODE,
    APP_SET_DIALOG_PARAMS,
    APP_SET_EDIT_ENTITY_ITEM,
    APP_SET_MODE, APP_SET_PREFILLED_PARAMS,
    APP_SET_SYSTEM_MESSAGE, APP_SHOW_COPY_DIALOG,
    APP_SHOW_DIALOG
} from '../types'

export const initialState: IAppState = {
    showOverpage: false,
    mode: 'default',
    currentEntity: '',
    editEntityItem: null,
    systemMessages: [],
    dialogIsOpen: false,
    dialogParams: {url: '', type: 'empty'},
    prefilledParams: {},
    copyDialogIsOpen: false,
}

export const appReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case APP_OVERPAGE: {
            return {...state, showOverpage: payload}
        }
        case APP_SET_MODE: {
            return {...state, mode: payload}
        }
        case APP_SET_CURRENT_ENTITY: {
            return {...state, currentEntity: payload}
        }
        case APP_SET_EDIT_ENTITY_ITEM: {
            return {...state, editEntityItem: payload}
        }
        case APP_SET_SYSTEM_MESSAGE: {
            return {...state, systemMessages: payload}
        }
        case APP_SHOW_DIALOG: {
            return {...state, dialogIsOpen: payload}
        }
        case APP_SHOW_COPY_DIALOG: {
            return {...state, copyDialogIsOpen: payload}
        }
        case APP_SET_DIALOG_PARAMS: {
            return {...state, dialogParams: payload}
        }
        case APP_SET_DIALOG_MODE: {
            return {...state, dialogParams: {...state.dialogParams, type: payload}}
        }
        case APP_SET_DIALOG_ENTITY: {
            return {...state, dialogParams: {...state.dialogParams, currentEntity: payload}}
        }
        case APP_SET_DIALOG_ITEM_ID: {
            return {...state, dialogParams: {...state.dialogParams, currentItemId: payload}}
        }
        case APP_SET_DIALOG_CURRENT_ENTITY_ITEM: {
            return {...state, dialogParams: {...state.dialogParams, currentEntityItem: payload}}
        }
        case APP_SET_PREFILLED_PARAMS: {
            return {...state, prefilledParams: payload}
        }
        default:
            return state
    }
}