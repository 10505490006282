import Box from '@material-ui/core/Box'
import Icon from '@material-ui/core/Icon'
import {useSnackbar} from 'notistack'
import React, {useEffect} from 'react'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuIcon from '@material-ui/icons/Menu'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {overpageShow} from '../../redux/actions/app'
import {logout} from '../../redux/actions/auth'
import {Link, useParams} from 'react-router-dom'
import {entities} from '../../data/entities'
import {APP_SET_SYSTEM_MESSAGE} from '../../redux/types'
import {IState} from '../../utils/models/IState'
import {IUrlParams} from '../../utils/models/IUrlParams'
import {fade} from '@material-ui/core/styles/colorManipulator'
import OverPage from '../Basics/Overpage'
import EntityForm from '../Forms/EntityForm'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        position: 'relative'
    },
    menu: {
        '& a': {
            color: '#4e4e4e',
            textDecoration: 'none',
            '& .MuiTypography-root': {
                fontSize: '0.9rem',
            },
            '& .material-icons': {
                marginRight: 10
            },
            '&.active > div': {
                background: fade(theme.palette.primary.main, 0.1),
                color: theme.palette.primary.main
            }
        }
    }
}))

function Layout(props) {
    const {entity: ent, nested} = useParams<IUrlParams>()
    const entity = nested ? ent + '/' + nested : ent
    const {window} = props
    const classes = useStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const {enqueueSnackbar} = useSnackbar()

    const {showOverpage, mode, systemMessages} = useSelector((state: IState) => state.app, shallowEqual)
    const {profile} = useSelector((state: IState) => state.user, shallowEqual)

    useEffect(() => {
        systemMessages.map((item) => {
            enqueueSnackbar(item.message, {
                variant: item.type,
                onEntered: () => dispatch({type: APP_SET_SYSTEM_MESSAGE, payload: []})
            })
        })
    }, [systemMessages])

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const handleProfileClick = () => {
        //dispatch(editEntity(profile.id))
    }

    const handleMenuClick = () => {
        dispatch(overpageShow(false))
    }

    const drawer = (
        <div>
            <div className={classes.toolbar}/>
            <Divider/>
            <List className={classes.menu}>
                {entities.map((item) => (
                    !item.hidden &&
                    <Link
                        key={item.code}
                        to={`/${item.code}/list`}
                        className={entity === item.code ? 'active' : ''}
                        onClick={handleMenuClick}
                    >
                        <ListItem button>
                            <Icon>{item.icon}</Icon>
                            <ListItemText primary={item.title}/>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </div>
    )

    const container = window !== undefined ? () => window().document.body : undefined

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar style={{justifyContent: 'space-between'}}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Колесо: Администрирование
                    </Typography>
                    <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                        <Box onClick={handleProfileClick}>
                            {profile.title}
                        </Box>
                        <IconButton
                            onClick={() => {
                                dispatch(logout())
                            }}
                            style={{color: '#fff'}}
                        >
                            <Icon>
                                logout
                            </Icon>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true,
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                {props.children}
                {showOverpage &&
                <OverPage
                    component={<EntityForm type={mode}/>}
                />
                }
            </main>
        </div>
    )
}

export default Layout
