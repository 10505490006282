import {combineReducers} from 'redux'
import {appReducer} from './appReducer'
import {authReducer} from './authReducer'
import {foodCategoryReducer} from './foodCategoryReducer'
import {galleryReducer} from './galleryReducer'
import {listReducer} from './listReducer'

export const rootReducer = combineReducers({
    app: appReducer,
    user: authReducer,
    list: listReducer,
    gallery: galleryReducer,
    foodCategory: foodCategoryReducer
})