import axios from 'axios'
import {Dispatch} from 'redux'
import {SET_GALLERY_DATA} from '../types'

export const getGalleryItems = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/gallery/${id}/photos?size=100`)
                .then(res => res.data)
            dispatch({type: SET_GALLERY_DATA, payload: data.content})
        } catch (err) {
            console.log(err)
        }
    }
}

export const deleteGalleryItem = (id: string, galleryId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const data = await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/gallery/photo`, {params: {id: id}})
                .then(res => res.data)
            dispatch(getGalleryItems(galleryId) as any)
        } catch (err) {
            console.log(err)
        }
    }
}