import {Dispatch} from 'redux'
import AuthService from '../../services/authService'
import {SET_USER, UNSET_USER} from '../types'

const authService = new AuthService()

export const auth = ({username, password}) => {
    return async (dispatch: Dispatch) => {
        try {
            await authService.login(username, password).then((user) => {
                dispatch({type: SET_USER, payload: user})
            })
        } catch (err) {
            console.log(err)
        }
    }
}

export const logout = () => {
    return async (dispatch: Dispatch) => {
        try {
            await authService.logout().then(()=>{
                dispatch({type: UNSET_USER})
            })
        } catch (err) {
            console.log(err)
        }
    }
}