import Box from '@material-ui/core/Box'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {makeStyles} from '@material-ui/core/styles'
import React, {useEffect, useState} from 'react'
import {IEntityField} from '../../../utils/models/IEntity'

interface ICheckboxGroupProps {
    name: string
    data?: any[]
    entityData?: IEntityField
    onChange?: (value: any[], name: string) => (e: React.ChangeEvent<HTMLInputElement>) => void
    variant?: any
}

export const CheckboxGroup = ({name, data, entityData, onChange, variant}: ICheckboxGroupProps) => {

    const [values, setValues] = useState(data || [])

    useEffect(() => {
        onChange(values, name)(null)
    }, [values])

    const handleChange = (e) => {
        values.includes(e.target.value)
            ? setValues(values.filter(item => item !== e.target.value))
            : setValues(values.concat([e.target.value]))
    }

    const useStyles = makeStyles(() => ({
        root: {
            flexDirection: variant,
            alignItems: variant ==='row' && 'center',
            border: '1px solid #ececec',
            borderRadius: 4,
            padding: '10px 20px',
            margin: '20px 0'
        },
        title: {
            marginRight: 20,
            color: 'rgba(0, 0, 0, 0.54)'
        },
        control:{
            margin: '0 25px 0 0'
        }
    }))

    const classes = useStyles()

    return (
        <FormGroup className={classes.root}>
            <Box className={classes.title}>
                {entityData.headerName}:
            </Box>
            {entityData.values &&
            entityData.values.map((item) => {
                return <FormControlLabel
                    className={classes.control}
                    control={
                        <Checkbox
                            checked={values.includes(item.value)}
                            disabled={item.disabled}
                            onChange={handleChange}
                            value={item.value}
                            color={'primary'}
                            name={name}
                        />
                    }
                    label={item.title}
                    key={item.value}
                />
            })
            }
        </FormGroup>
    )
}

export default CheckboxGroup