import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route
} from 'react-router-dom'
import LoginPage from '../pages/LoginPage'
import List from '../pages/MainPage'
import {AuthProvider} from '../providers/authProvider'
import {PrivateRoute} from './privateRoute'

const routes = [
    {
        path: '/login',
        component: LoginPage,
        isPrivate: false
    },
    {
        path: '/:entity/:nested/list',
        component: List,
        isPrivate: true
    },
    {
        path: '/:entity/list',
        component: List,
        isPrivate: true
    },
    {
        path: '/',
        component: List,
        isPrivate: true
    }
]

export default function RouteConfig() {
    return (
        <AuthProvider>
            <Router>
                <Switch>
                    {routes.map((route) => (
                        route.isPrivate ?
                            <PrivateRoute path={route.path} key={route.path} component={route.component}/>
                            :
                            <Route key={route.path} path={route.path} component={route.component}/>
                    ))}
                </Switch>
            </Router>
        </AuthProvider>
    )
}