import {IListState} from '../../utils/models/IListState'
import {
    LIST_LOADING,
    SET_LIST_CURRENT_PAGE,
    SET_LIST_DATA,
    SET_LIST_FIELDS, SET_LIST_SELECTION_MODEL, SET_LIST_SORT_MODEL,
    SET_LIST_TOTAL_ELEMENTS,
    SET_LIST_TOTAL_PAGES
} from '../types'

const initialState: IListState = {
    data: [],
    fields: [],
    loading: false,
    totalPages: 0,
    totalElements: 0,
    currentPage: 0,
    sortModel: {field:'', sort: 'asc'},
    selectionModel: []
}

export const listReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_LIST_DATA: {
            return {...state, data: payload}
        }
        case SET_LIST_FIELDS: {
            return {...state, fields: payload}
        }
        case LIST_LOADING: {
            return {...state, loading: payload}
        }
        case SET_LIST_TOTAL_PAGES: {
            return {...state, totalPages: payload}
        }
        case SET_LIST_TOTAL_ELEMENTS: {
            return {...state, totalElements: payload}
        }
        case SET_LIST_CURRENT_PAGE: {
            return {...state, currentPage: payload}
        }
        case SET_LIST_SORT_MODEL: {
            return {...state, sortModel: payload}
        }
        case SET_LIST_SELECTION_MODEL: {
            return {...state, selectionModel: payload}
        }
        default:
            return state
    }
}