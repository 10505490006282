import Box from '@material-ui/core/Box'
import {makeStyles} from '@material-ui/core/styles'
import {useEffect, useState} from 'react'
import {Editor} from 'react-draft-wysiwyg'
import {EditorState, ContentState, convertToRaw} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

const useStyles = makeStyles(() => ({
    root: {
        border: '1px solid #ececec',
        padding: 15,
        borderRadius: 4,
        marginBottom: 20,
        '& .rdw-editor-toolbar':{
            fontSize: 13,
            '& .rdw-option-wrapper':{
                padding: 2,
                minWidth: 21
            }
        }
    }
}))

export const TextAreaEditor = ({name, onChange, label, value}) => {
    const classes = useStyles()
    let editorState = null
    if (value) {
        const blocksFromHtml = htmlToDraft(value)
        const {contentBlocks, entityMap} = blocksFromHtml
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap)
        editorState = EditorState.createWithContent(contentState)
    }

    const [editorSt, setEditorSt] = useState(editorState || EditorState.createEmpty())

    const onEditorStateChange = (editorState) => {
        setEditorSt(editorState)
    }

    useEffect(() => {
        const rawContentState = convertToRaw(editorSt.getCurrentContent())
        const markup = draftToHtml(
            rawContentState
        )
        onChange(markup, name)(null)
    }, [editorSt])

    return (
        <Box>
            <span className={`MuiFormLabel-root`} style={{fontSize: '0.875rem'}}>{label}</span>
            <Box className={classes.root}>
                <Editor
                    editorState={editorSt}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                    localization={{
                        locale: 'ru',
                    }}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'link', 'emoji', 'history']}}
                    stripPastedStyles={true}
                />
            </Box>
        </Box>
    )
}

export default TextAreaEditor