import {IGalleryState} from '../../utils/models/IGallery'
import {
    SET_GALLERY_DATA

} from '../types'

export const initialState: IGalleryState = {
    items: []
}

export const galleryReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_GALLERY_DATA: {
            return {...state, items: payload}
        }
        default:
            return state
    }
}