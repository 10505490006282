import {createMuiTheme} from '@material-ui/core/styles'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#008bf6',
            dark: '#189cff'
        },
        secondary: {
            main: '#ff4e8e',
            dark: '#fff'
        },
        success:{
            main: '#06c368'
        }
    },
    overrides: {
        MuiTypography:{
            caption:{
                color: '#bfbfbf'
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none'
            }
        },
        MuiListItemIcon: {
            root: {
                minWidth: 34,
                marginRight: 10
            }
        },
        MuiInputLabel: {
            root: {
                fontSize: '0.85rem'
            },
            shrink: {
                marginTop: 2
            }
        },
        MuiInputBase: {
            root: {
                '&:before': {
                    borderColor: '#d6d6d6!important'
                }
            },
            input: {
                fontSize: '0.9rem'
            },
        },
        MuiOutlinedInput:{
            root:{
                '& fieldset':{
                    borderColor: '#dddddd',
                },
                '&:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#d6d6d6',
                        borderWidth: 2
                    }
                },
                '&.Mui-focused:hover': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#008bf6'
                    }
                }
            }
        },
        MuiFormControlLabel: {
            label: {
                fontSize: '0.85rem'
            }
        },
        MuiCheckbox:{
            root: {
                padding: 5,
                color: 'rgba(0, 0, 0, 0.25)'
            }
        }
    }
})

export default theme
