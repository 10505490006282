import {makeStyles, Theme} from '@material-ui/core/styles'
import React from 'react'
import FoodItemsTable from './FoodItemsTable'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: 20
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 3
    }
}))

interface IFoodCategoryItems {
    category?: any
}

export const FoodCategoryItems = ({category}: IFoodCategoryItems) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <FoodItemsTable />
        </div>
    )
}

export default FoodCategoryItems