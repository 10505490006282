import {makeStyles} from '@material-ui/core/styles'
import React, {useState} from 'react'
import {Checkbox, FormControlLabel, TextField} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import {useFormik} from 'formik'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {entities} from '../../data/entities'
import {getAssignedObject, getEmptyEntityFields, getValidationSchema} from '../../helpers/formHelpers'
import {createEntityItem, deleteEntity, showCopyDialog, updateEntityItem} from '../../redux/actions/app'
import {APP_SET_DIALOG_CURRENT_ENTITY_ITEM} from '../../redux/types'
import {IAppState, TAppMode} from '../../utils/models/IAppState'
import {IState} from '../../utils/models/IState'
import {CopyDialog} from '../Misc/CopyDialog'
import FoodCategoryItems from '../Misc/FoodCategoryItems'
import PhotoGallery from '../Misc/PhotoGallery'
import CheckboxGroup from './Elements/CheckboxGroup'
import DatePicker from './Elements/DatePicker'
import FileUploader from './Elements/FileUploader'
import TextAreaEditor from './Elements/TextAreaEditor'

interface IEntityFormProps {
    type: TAppMode
    inModal?: boolean
    onSaveClick?: () => void
    onSaveDelete?: () => void
}

const useStyles = makeStyles(() => ({
    root: {
        marginBottom: 30
    },
    field: {
        position: 'relative'
    },
    errorBox: {
        color: '#ff2668',
        fontSize: 11,
        position: 'absolute',
        bottom: -16,
        left: 0
    }
}))

export const EntityForm = ({type, inModal = false, onSaveClick}: IEntityFormProps) => {

    const classes = useStyles()
    const {copyDialogIsOpen} = useSelector((state: IState) => state.app, shallowEqual)
    const {
        currentEntity,
        mode,
        editEntityItem,
        prefilledParams
    } = useSelector((state: IState) => state.app, shallowEqual)
    const {
        currentEntity: mCurrentEntity,
        currentEntityItem
    } = useSelector((state: IState) => state.app.dialogParams, shallowEqual)
    const dispatch = useDispatch()

    const formMode = type || mode
    const localCurrentEntity = inModal ? mCurrentEntity : currentEntity
    const localEditEntityItem = inModal ? currentEntityItem : editEntityItem

    const entityData = entities.find(item => {
        return item.code === localCurrentEntity
    })

    const fieldsMap = entityData.fieldsMap

    const initialValues = formMode !== 'create'
        ? getAssignedObject(fieldsMap, localEditEntityItem)
        : getEmptyEntityFields(fieldsMap, prefilledParams)

    const validationSchema = getValidationSchema(fieldsMap)

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (formMode === 'create') {
                dispatch(createEntityItem(values, localCurrentEntity))
            } else {
                dispatch(updateEntityItem(localEditEntityItem.id, values, inModal))
            }
        },
    })

    const handleChange = (value = null, name = '') => (e) => {
        const val = value || (e && e.target.value) || ''
        const fieldName = name || e.target.name
        formik.setFieldValue(fieldName, val)
    }

    const handleDelete = () => {
        dispatch(deleteEntity(localEditEntityItem.id))
    }

    const handleCopy = () => {
        dispatch(showCopyDialog(true))
    }

    return (
        <Box className={classes.root}>
            {!inModal &&
            <Box pb={2}>
                <Typography variant={'h5'}>
                    {formMode !== 'create' ? 'Редактирование' : 'Добавление'} {entityData.title2}
                </Typography>
            </Box>
            }
            <Paper style={{padding: 20}}>
                <form onSubmit={formik.handleSubmit} id={inModal ? 'modal-form' : 'straight-form'}>
                    {fieldsMap ?
                        fieldsMap.map((item) => {
                            let params = {}
                            if (item.additionalParams && editEntityItem) {
                                item.additionalParams.map((param) => {
                                    params[param.param] = editEntityItem[param.objParam]
                                })
                            }
                            switch (item.fieldType) {
                                case 'text' :
                                case 'textarea':
                                    return (
                                        (formMode === 'create' && item.editability === 'auto') ? null :
                                            <Box key={item.field} className={classes.field}
                                                 maxWidth={item.fieldType === 'text' ? 500 : '100%'} mb={2}>
                                                <TextField
                                                    label={item.headerName}
                                                    value={formik.values[item.field]}
                                                    size={'small'}
                                                    name={item.field}
                                                    fullWidth
                                                    onChange={handleChange()}
                                                    autoComplete={'off'}
                                                    disabled={(item.editability === 'auto' || item.editability === 'readonly')}
                                                    multiline={item.fieldType === 'textarea'}
                                                    rows={6}
                                                    variant={item.fieldType === 'textarea' ? 'outlined' : 'standard'}
                                                />
                                                {
                                                    (formik.errors[item.field] && formik.touched[item.field]) &&
                                                    <Box className={classes.errorBox}>{
                                                        formik.errors[item.field]}
                                                    </Box>
                                                }
                                            </Box>
                                    )
                                case 'password':
                                    return (
                                        <Box key={item.field} maxWidth={500} mb={2}>
                                            <TextField
                                                label={item.headerName}
                                                value={formik.values[item.field]}
                                                size={'small'}
                                                name={item.field}
                                                fullWidth
                                                onChange={handleChange()}
                                                type={'password'}
                                                autoComplete={'new-password'}
                                                disabled={(item.editability === 'auto' || item.editability === 'readonly')}
                                            />
                                        </Box>
                                    )
                                case 'checkboxGroup':
                                    return (
                                        <Box style={{position: 'relative'}} key={item.field}>
                                            <CheckboxGroup
                                                name={item.field}
                                                data={formik.values[item.field]}
                                                entityData={item}
                                                onChange={handleChange}
                                                variant={item.variant}
                                            />
                                            {
                                                (formik.errors[item.field] && formik.touched[item.field]) &&
                                                <Box className={classes.errorBox}>{
                                                    formik.errors[item.field]}
                                                </Box>
                                            }
                                        </Box>
                                    )
                                case 'date':
                                    return (
                                        (formMode === 'create' && item.editability === 'auto') ? null :
                                            <DatePicker
                                                key={item.field}
                                                value={formik.values[item.field]}
                                                label={item.headerName}
                                                name={item.field}
                                                onChange={handleChange}
                                                disabled={(item.editability === 'auto' || item.editability === 'readonly')}
                                            />
                                    )
                                case 'htmlEditor':
                                    return (
                                        <TextAreaEditor
                                            key={item.field}
                                            name={item.field}
                                            label={item.headerName}
                                            value={formik.values[item.field]}
                                            onChange={handleChange}
                                        />
                                    )
                                case 'file':
                                    return (
                                        (formMode === 'create' && item.editability === 'noCreate') ? null :
                                            <FileUploader
                                                key={item.field}
                                                name={item.field}
                                                label={item.headerName}
                                                value={formik.values[item.field]}
                                                onChange={handleChange}
                                                complementaryProps={item.complementaryProps}
                                                acceptTypes={item.accept}
                                                path={item.uploaderPath || `/api/admin/storage/file`}
                                                additionalParams={params}
                                                modal={inModal}
                                            />
                                    )
                                case 'boolean':
                                    return (
                                        <FormControlLabel
                                            key={item.field}
                                            control={
                                                <Checkbox
                                                    checked={formik.values[item.field]}
                                                    onChange={handleChange(!formik.values[item.field], item.field)}
                                                    name={item.field}
                                                    color="primary"
                                                />
                                            }
                                            label={item.headerName}
                                        />
                                    )
                                default:
                                    return ''
                            }
                        })
                        :
                        <Box>
                            Отсутствуют настроенные поля
                        </Box>
                    }
                    {localCurrentEntity === 'gallery' && formMode === 'edit' &&
                    <PhotoGallery gallery={localEditEntityItem}/>
                    }
                    {localCurrentEntity === 'food-category' && formMode === 'edit' &&
                    <FoodCategoryItems category={localEditEntityItem}/>
                    }
                    {fieldsMap && !inModal &&
                    <Box pt={2} style={{display: 'flex', justifyContent: 'space-between'}}>
                        {formMode === 'edit' ?
                            <>
                                {!entityData.nonEditable &&
                                <Button type={'submit'} disableElevation variant={'contained'}
                                        color={'primary'}>Сохранить</Button>
                                }
                                {entityData.copyable &&
                                <Button disableElevation variant={'outlined'} onClick={handleCopy}
                                        color={'primary'}>Копировать</Button>
                                }
                                <Button variant={'outlined'} onClick={handleDelete} color={'secondary'}>Удалить</Button>
                            </>
                            :
                            <Button type={'submit'} disableElevation variant={'contained'}
                                    color={'primary'}>Добавить</Button>
                        }
                    </Box>
                    }
                </form>
                {copyDialogIsOpen && <CopyDialog item={localEditEntityItem} />}
            </Paper>
        </Box>
    )
}

export default EntityForm