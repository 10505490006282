import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import {makeStyles, Theme, lighten} from '@material-ui/core/styles'
import {useEffect} from 'react'
import * as React from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {setDialogItemId, setPrefilledParams} from '../../redux/actions/app'
import {getFoodCategoryItems} from '../../redux/actions/foodCategory'
import {IState} from '../../utils/models/IState'
import {entities} from '../../data/entities'

const useStyles = makeStyles((theme: Theme) => ({
    table: {
        cursor: 'default',
    },
    tableRow: {
        '&:hover': {
            background: lighten(theme.palette.primary.main, 0.9)
        },
        cursor: 'pointer',
    },
    addBtn: {
        color: '#07a769',
        marginLeft: 5,
        padding: 3,
    },
    removeBtn: {
        color: '#ff5252',
        padding: 5,
        marginLeft: 10
    },
    labelBlock: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: 5
    },
    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        marginBottom: 3
    }
}))


export const FoodItemsTable = () => {

    const classes = useStyles()
    const entity = 'food-item'
    const entityData = entities.find(item => {
        return item.code === entity
    })

    const {editEntityItem, dialogParams} = useSelector((state: IState) => state.app, shallowEqual)
    const {items} = useSelector((state: IState) => state.foodCategory, shallowEqual)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getFoodCategoryItems(editEntityItem.id))
    }, [])

    const handleRowClick = (id, entity) => () => {
        dispatch(setDialogItemId(id, entity, 'edit'))
    }

    const handleAddClick = (id, entity) => () => {
        console.log(editEntityItem.id)
        dispatch(setDialogItemId(id, entity, 'create'))
        dispatch(setPrefilledParams({categoryId: editEntityItem.id}))
    }

    return (
        <div>
            <div className={classes.labelBlock}>
                <div className={classes.label}>Блюда</div>
                <IconButton className={classes.addBtn} onClick={handleAddClick('', entity)}>
                    <Icon style={{fontSize: 20}}>add</Icon>
                </IconButton>
            </div>
            <TableContainer component={Paper} style={{maxWidth: 1000}}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {entityData.fieldsMap.map((field) => {
                                return !field.hide && <TableCell key={field.field}>{field.headerName}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => {
                            return (<TableRow className={classes.tableRow} key={item.id}
                                              onClick={handleRowClick(item.id, entity)}>
                                {
                                    entityData.fieldsMap.map((field) => {
                                        if (field.field === 'imagePath') {
                                            return <TableCell width={40} key={field.field}>
                                                <img style={{height: 32, borderRadius: 4}}
                                                     src={`${process.env.REACT_APP_API_URL}/files/${item[field.field]}`}/>
                                            </TableCell>
                                        }
                                        return !field.hide && <TableCell key={field.field}>{item[field.field]}</TableCell>
                                    })
                                }

                            </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default FoodItemsTable
