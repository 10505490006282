import Box from '@material-ui/core/Box'
import {GridColTypeDef} from '@material-ui/data-grid'
import React from 'react'

const currencyFormatter = new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
});
export const rubPrice: GridColTypeDef = {
    type: 'number',
    valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
    cellClassName: 'price'
}

export const email: GridColTypeDef = {
    renderCell: ({value}) => {
        return (
            <a href={`mailto:${value}`}>{value}</a>
        )
    },
    cellClassName: 'email'
}

export const bool: GridColTypeDef = {
    renderCell: ({value}) => {
        return (
            <>{value ? 'Да' : 'Нет'}</>
        )
    },
    cellClassName: 'boolean'
}

export const url: GridColTypeDef = {
    renderCell: ({value}) => {
        return (
            <a href={`//${value}`} target={'_blank'}>{value}</a>
        )
    },
    cellClassName: 'url'
}

export const dateTime: GridColTypeDef = {
    valueFormatter: ({value}) => {
        if (value) {
            const options: Intl.DateTimeFormatOptions = {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit'
            }
            const date = new Date(value.toString())
            return date.toLocaleString('ru', options)
        }
        return ''
    },
    cellClassName: 'dateTime'
}

export const html: GridColTypeDef = {
    renderCell: ({value}) => {
        return (
            <Box dangerouslySetInnerHTML={{__html: value.toString()}}/>
        )
    },
    cellClassName: 'htmlContent'
}

export const image: GridColTypeDef = {
    renderCell: ({value}) => {
        return (
            value &&
            <Box style={{width: 40, height: 40, padding: 5}}>
                <img style={{width:'100%'}}
                     src={`${process.env.REACT_APP_API_URL}/files/${value.toString()}`}/>
            </Box>
        )
    },
    cellClassName: 'imageContent'
}