import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import React, {useState} from 'react'
import {Helmet} from 'react-helmet-async'
import {useDispatch} from 'react-redux'
import useUser from '../../hooks/useUser'
import {auth, logout} from '../../redux/actions/auth'

export const LoginForm = () => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const {profile} = useUser()

    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(auth({username, password}))
    }

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <div style={{ //TODO: This is temp -> all styles to classes
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '90vh'
        }}>
            <Helmet>
                <title>Колесо: Администрирование</title>
            </Helmet>
            <Paper
                style={{alignSelf: 'center', display: 'flex', flexDirection: 'column', padding: '20px 40px 40px 40px', margin: 30}}
                elevation={5}
            >{
                profile?.username ?
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                        height: 300
                    }}>
                        <Typography color={'primary'} variant={'h6'} style={{display: 'flex'}}>Колесо:
                            Вы авторизованы</Typography>
                        <span>
                        {profile?.title}
                        </span>
                        <Button variant={'outlined'} color={'secondary'}
                                onClick={handleLogout}>Logout
                        </Button>
                    </Box>
                    :
                    <form onSubmit={handleSubmit} style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        height: 300
                    }}>
                        <Typography color={'primary'} variant={'h6'} style={{display: 'flex'}}>Колесо:
                            Администрирование</Typography>
                        <TextField
                            id="username"
                            label="Логин"
                            variant="outlined"
                            size={'small'}
                            value={username}
                            fullWidth
                            onChange={(e) => {
                                setUsername(e.target.value)
                            }}
                        />
                        <TextField
                            id="password"
                            label="Пароль"
                            variant="outlined"
                            size={'small'}
                            value={password}
                            fullWidth
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                            type={'password'}
                        />
                        <Button
                            variant={'contained'}
                            size={'large'}
                            fullWidth
                            color={'primary'}
                            type="submit">
                            Войти
                        </Button>
                    </form>
            }
            </Paper>
        </div>
    )
}

export default LoginForm