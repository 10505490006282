import axios from 'axios'
import {Dispatch} from 'redux'
import {SET_FOOD_CATEGORY_DATA} from '../types'

export const getFoodCategoryItems = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const data = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/food-category/${id}/items?size=100`)
                .then(res => res.data)
            dispatch({type: SET_FOOD_CATEGORY_DATA, payload: data.content})
        } catch (err) {
            console.log(err)
        }
    }
}

export const deleteFoodCategoryItem = (id: string, categoryId: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const data = await axios.delete(`${process.env.REACT_APP_API_URL}/api/admin/food-item`, {params: {id: id}})
                .then(res => res.data)
            dispatch(getFoodCategoryItems(categoryId) as any)
        } catch (err) {
            console.log(err)
        }
    }
}