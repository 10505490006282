import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import {makeStyles, Theme} from '@material-ui/core/styles'
import React, {useEffect} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {deleteGalleryItem, getGalleryItems} from '../../redux/actions/gallery'
import {IState} from '../../utils/models/IState'
import FileUploader from '../Forms/Elements/FileUploader'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: 20
    },
    itemsContainer:{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        border: '1px solid #ececec',
        borderRadius: 4
    },
    image: {
        maxWidth: 200,
        maxHeight: 135,
        border: '1px solid #ececec',
        borderRadius: 4,
        padding: 5,
        margin: 10,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '& img': {
            maxHeight: '100%',
            maxWidth: '100%'
        }
    },
    deleteButton: {
        position: 'absolute',
        top: 3,
        left: 3,
        background: 'rgba(255,255,255,0.65)',
        padding: 5,
        color: 'rgba(255,38,104,1)',
        border: 'rgba(255,255,255,0.8)',
        '& .material-icons': {
            fontSize: 20
        },
        '&:hover': {
            background: 'rgba(255,255,255,0.8)',
        }
    }
}))

export const PhotoGallery = ({gallery}) => {

    const classes = useStyles()

    const {items} = useSelector((state: IState) => state.gallery, shallowEqual)

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(getGalleryItems(gallery.id))
    },[])

    const handleChange = () => () => {
        dispatch(getGalleryItems(gallery.id))
    }

    const handleDelete = (item) => (e) => {
        dispatch(deleteGalleryItem(item.id, gallery.id))
        console.log(item)
    }

    return (
        <div className={classes.root}>
            <FileUploader
                name={'gallery'}
                label={`Файлы (${items.length})`}
                value={''}
                complementaryProps={[]}
                acceptTypes={['image/jpeg', 'image/png']}
                multiple={true}
                path={'/api/admin/gallery/photo/upload'}
                additionalParams={{galleryId: gallery.id}}
                onChange={handleChange}
            />
            <div className={classes.itemsContainer}>
                {items && items.length > 0 && items.map((item)=>{
                    return (
                        <div key={item.id} className={classes.image}>
                            <IconButton onClick={handleDelete(item)} className={classes.deleteButton}>
                                <Icon>highlight_off</Icon>
                            </IconButton>
                            <img src={`${process.env.REACT_APP_API_URL}/files/${item.imagePath}`}/>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default PhotoGallery