import {CircularProgress, TextField} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import {useFormik} from 'formik'
import React, {FC, useState} from 'react'
import {useDispatch} from 'react-redux'
import {showCopyDialog} from '../../redux/actions/app'
import DatePicker from '../Forms/Elements/DatePicker'

interface ICopyDialogProps {
    item: any
}

export const CopyDialog: FC<ICopyDialogProps> = ({item}) => {

    const [status, setStatus] = useState<'default' | 'progress' | 'success'>('default')
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {id: item.id, startTime: '', endTime: ''},
        onSubmit: (values) => {
            setStatus('progress')
            axios.post(`${process.env.REACT_APP_API_URL}/api/admin/event/copy`, values).then(res => {
                setStatus('success')
                setTimeout(() => {
                    dispatch(showCopyDialog(false))
                }, 1000)
            }).catch(err => {
                setStatus('default')
                window.alert(err)
                console.log(err)
            })
        },
    })

    const handleChange = (value = null, name = '') => (e) => {
        const val = value || (e && e.target.value) || ''
        const fieldName = name || e.target.name
        formik.setFieldValue(fieldName, val)
    }

    const handleClose = () => {
        dispatch(showCopyDialog(false))
    }

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            background: '#00000050',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            paddingLeft: 260,
            zIndex: 500
        }}>
            <div style={{
                width: 500,
                background: '#fff',
                padding: '20px 30px 40px 30px',
                borderRadius: 4,
                position: 'relative'
            }}>
                <IconButton
                    onClick={handleClose}
                    style={{position: 'absolute', top: 10, right: 10, padding: 0}}><Icon>close</Icon></IconButton>
                <Typography style={{maxWidth: 450}} variant={'h6'}>Копирование события<br />'{item.title}'</Typography>
                <form onSubmit={formik.handleSubmit} id={'copyDialogForm'} style={{position: 'relative'}}>
                    {status !== 'default' &&
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0,
                        background: '#ffffffaa',
                        zIndex: 100,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {status === 'progress' && <CircularProgress style={{marginBottom: 10}} color='primary'/>}
                        {status === 'progress' ? 'Копируем...' : 'Событие успешно скопировано!'}
                    </div>
                    }
                    <TextField name={'id'} type={'hidden'} value={formik.values.id}/>
                    <DatePicker
                        value={formik.values.startTime}
                        label={'Начало события'}
                        name={'startTime'}
                        onChange={handleChange}
                        disabled={false}
                        disablePast
                    />
                    <DatePicker
                        value={formik.values.startTime}
                        label={'Конец события'}
                        name={'endTime'}
                        onChange={handleChange}
                        disabled={false}
                        disablePast
                    />
                    <Button style={{marginTop: 20}} type={'submit'} color={'primary'} variant={'contained'}
                            disableElevation>Копировать</Button>
                </form>
            </div>
        </div>
    )
}