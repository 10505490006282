import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import {Provider} from 'react-redux'
import App from './App'
import store from './redux/store'
import theme from './theme'
import {MuiThemeProvider} from '@material-ui/core'
import {Helmet, HelmetProvider} from 'react-helmet-async'

ReactDOM.render(
    <Provider store={store}>
    <MuiThemeProvider theme={theme}>
        <HelmetProvider>
            <Helmet>
                <meta name='viewport'
                      content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'/>
                <meta name='theme-color' content='#ffffff'/>
                <meta name='msapplication-navbutton-color' content='#ffffff'/>
                <meta name='apple-mobile-web-app-status-bar-style' content='#ffffff'/>
                <link rel='stylesheet' href='https://fonts.googleapis.com/icon?family=Material+Icons'/>
            </Helmet>
            <App/>
        </HelmetProvider>
    </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
)
